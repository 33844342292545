import ComingSoon from "./pages/coming-soon";
import CoursePage from "./pages/coursepage";
import HomePage from "./pages/homepage";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
function App() {
  return (
    <Router>
      <div className="App">
        <Routes>
          <Route path="/" element={<ComingSoon />}></Route>
          <Route path="/landing" element={<HomePage />}></Route>
          <Route path="/course" element={<CoursePage />}></Route>
        </Routes>
      </div>
    </Router>
  );
}

export default App;
