import { useNavigate } from "react-router-dom";
import "./course-main.css";
export default function CourseMain() {
  const routeOut = (url) => {
    window.location.href = url;
  };
  return (
    <div className="container">
      <div className="course-main">
        <div>
          <img src="/assets/youtuber.png" alt="youtuber" />
          <div className="cta-text montserrat-family">
            Crack your F1 Visa <br /> like a{" "}
            <span style={{ color: "#B5E156" }}>Rockstar!</span>🗽
          </div>
          <div className="cta-detail">
            In less than 2 hours, you'll learn every technique you need to nail
            your interview! In this course, everything you learn is based on
            personal experience and training from my previous webinars
          </div>
          <div className="cta-buy-btns">
            <div style={{ display: "flex", alignItems: "end" }}>
              <button
                className="register-btn"
                onClick={() =>
                  routeOut(
                    `${process.env.REACT_APP_LMS_URI}/student-registration/?redirect_to=${process.env.REACT_APP_LMS_URI}`
                  )
                }
              >
                Register Today
              </button>
            </div>
            <div>
              <div className="discount-ends-at">50% Discount ends at</div>
              <button className="discount-btn">1 9 7 5 2</button>
            </div>
          </div>
          <div className="d-flex align-items-center gap-4">
            <div className="persons">
              <img src="/assets/person/person1.png" alt="person1" />
              <img src="/assets/person/person2.png" alt="person2" />
              <img src="/assets/person/person3.png" alt="person3" />
              <img src="/assets/person/person4.png" alt="person4" />
            </div>
            <div className="reviews">
              <div className="number">10,000+</div>
              <div className="score">F1 Rockstars!</div>
            </div>
            <div
              style={{ height: "40px", width: "2px", background: "#C13438" }}
            ></div>
            <div className="ratings pl-5">
              <div className="d-flex align-items-center">
                <div className="r-score">4.8+</div>
                <div className="r-buy">(600+Ratings)</div>
              </div>
              <div>
                <img src="/assets/star.png" alt="star" />
                <img src="/assets/star.png" alt="star" />
                <img src="/assets/star.png" alt="star" />
                <img src="/assets/star.png" alt="star" />
                <img src="/assets/star.png" alt="star" />
              </div>
            </div>
          </div>
        </div>
        <div className="person-image">
          <img src="/assets/young-man.png" alt="young-man" />
        </div>
      </div>
    </div>
  );
}
