import CourseCurriculum from "../components/course-curriculum";
import CourseDetail from "../components/course-detail";
import CourseMain from "../components/course-main";
import CourseReviews from "../components/course-reveiws";
import CourseHeader from "../components/header/course-header";

export default function CoursePage() {
  return (
    <div>
      <div className="course-bg">
        <CourseHeader />
        <CourseMain />
      </div>
      <CourseDetail />
      <CourseCurriculum />
      <CourseReviews />
    </div>
  );
}
