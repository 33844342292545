import Header from "../components/header/header";
import MainContent from "../components/main-content/main-content";
import Services from "../components/services/services";
import { StudentStories } from "../components/student-stories";
import Footer from "../components/footer";

export default function HomePage() {
  return (
    <>
      <div className="container">
        <Header />
      </div>
      <Services />
      <div className="main-content">
        <div className="container">
          <MainContent />
        </div>
      </div>
      <div className="mt-5">
        <StudentStories />
      </div>
      <div>
        <Footer />
      </div>
    </>
  );
}
