import "./services.css";
export default function Services() {
  const services = [
    {
      detail: "Complete F1 Visa Process",
      icons: "/assets/star.svg",
    },
    {
      detail: "Complete Canada Visa Process",
      icons: "/assets/star.svg",
    },
    {
      detail: "Things to Buy Before Leaving USA",
      icons: "/assets/star.svg",
    },
    {
      detail: "Things to Buy After Landing In USA",
      icons: "/assets/star.svg",
    },
    {
      detail: "Register For Webinar",
      icons: "/assets/star.svg",
    },
    {
      detail: "Book an Appointment",
      icons: "",
    },
  ];
  return (
    <div className="services inter">
      <div className="container justify-content-between d-none d-lg-flex d-xl-flex">
        {services.map((service) => {
          return (
            <>
              <a href="/">{service.detail}</a>
            </>
          );
        })}
      </div>
      <div
        className="container d-block d-lg-none d-xl-none"
        style={{ whiteSpace: "nowrap", overflow: "auto" }}
      >
        {services.map((service) => {
          return (
            <>
              <a
                href="/"
                className="d-inline-block"
                style={{ padding: "20px" }}
              >
                {service.detail}
              </a>
            </>
          );
        })}
      </div>
    </div>
  );
}
