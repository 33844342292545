import { useEffect } from "react";
import { Slide } from "react-slideshow-image";
import "./student-stories.css";
import "react-slideshow-image/dist/styles.css";

export function StudentStories() {
  const responsiveSettings = [
    {
      breakpoint: 992,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 576,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 300,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ];
  useEffect(() => {});
  return (
    <div className="student-stories inter">
      <h1 className="text-center student-heading">Student Stories</h1>
      <p className="text-center">Hear From Our Success Stories</p>
      <div className="col-12">
        <Slide
          slidesToShow={3}
          slidesToScroll={1}
          indicators={false}
          arrows={false}
          responsive={responsiveSettings}
        >
          <div className="slide text-center mt-4">
            <div className="card w-100 card-cstm">
              <div className="card-body">
                <div className="d-flex justify-content-around py-5">
                  <img src="/assets/group-21.png" alt="img" />
                </div>
                <div className="text-center">
                  <h1>Emily L</h1>
                  <p>Former Student</p>
                </div>
                <div className="mb-5">
                  "The support and guidance I received from The US Academy were
                  invaluable. From navigating the visa process to settling into
                  life in the USA, their expertise made all the difference"
                </div>
              </div>
            </div>
          </div>
          <div className="slide text-center mt-4">
            <div className="card w-100 card-cstm">
              <div className="card-body">
                <div className="d-flex justify-content-around py-5">
                  <img src="/assets/group-21.png" alt="img" />
                </div>
                <div className="text-center">
                  <h1>Sarah M</h1>
                  <p>Former Student</p>
                </div>
                <div className="mb-5">
                  "I can't thank The US Academy enough for their role in helping
                  me achieve my dream of studying in the USA. Their advice and
                  encouragement empowered me to overcome challenges and thrive
                  in my new academic environment."
                </div>
              </div>
            </div>
          </div>
          <div className="slide text-center mt-4">
            <div className="card w-100 card-cstm">
              <div className="card-body">
                <div className="d-flex justify-content-around py-5">
                  <img src="/assets/group-21.png" alt="img" />
                </div>
                <div className="text-center">
                  <h1>Rahul S</h1>
                  <p>Former Student</p>
                </div>
                <div className="mb-5">
                  "The support and guidance I received from The US Academy were
                  invaluable. From navigating the visa process to settling into
                  life in the USA, their expertise made all the difference."
                </div>
              </div>
            </div>
          </div>
        </Slide>
      </div>
    </div>
  );
}
