import "./course-header.css";
export default function CourseHeader() {
  return (
    <div className="main">
      <div className="container course-header">
        <div>
          <img src="/assets/logo-light.png" alt="light-logo" />
        </div>
        <div className="buttons">
          <a href="/">HOME</a>
          <a href="/">E-BOOKS</a>
        </div>
        <div>
          <a
            className="cta-btns sign-in"
            href={`${process.env.REACT_APP_LMS_URI}/dashboard`}
          >
            Sign In
          </a>
        </div>
      </div>
    </div>
  );
}
