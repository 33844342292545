import "./course-reveiws.css";

export default function CourseReviews() {
  return (
    <div className="cr-bg text-center">
      <h1 className="cr-heading py-5">
        Here's is what our F1 Rockstars had to say...
      </h1>
    </div>
  );
}
