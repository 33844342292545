import "./course-detail.css";

export default function CourseDetail() {
  const courseDetail = [
    {
      img: "/assets/course-detail/c-detail-1.png",
      heading: "What is this Course?",
      detail:
        "This is a groundbreaking time management and productivity course that will transform the way you work and live. I will teach you my personal secrets and the practical, step-by-step techniques that I use to achieve exponential productivity.",
      reverse: false,
    },
    {
      img: "/assets/course-detail/c-detail-2.png",
      heading: "What will I Learn?",
      detail:
        "You will learn how to live every single day of your life to your true potential. This is not a philosophical or a spiritual course, but rather a hands-on approach to help achieve your goals.",
      reverse: true,
    },
    {
      img: "/assets/course-detail/c-detail-3.png",
      heading: "Never Burnout Ever Again!",
      detail:
        "This is a groundbreaking time management and productivity course that will transform the way you work and live. I will teach you my personal secrets and the practical, step-by-step techniques that I use to achieve exponential productivity.",
      reverse: false,
    },
  ];
  return (
    <div className="course-detail">
      <div className="container">
        {courseDetail.map((course) => {
          return <CourseDetailCard {...course} />;
        })}
      </div>
    </div>
  );
}

const CourseDetailCard = ({ img, heading, detail, reverse = false }) => {
  return (
    <div className="row mt-4">
      <div className="col-5">
        <img className="w-100" src={img} alt="course-detail-1" />
      </div>
      <div
        className={`col-7 d-flex align-items-center justify-content-center ${
          reverse ? "order-first" : ""
        }`}
      >
        <div>
          <div className="cd-heading">{heading}</div>
          <div className="cd-detail">{detail}</div>
        </div>
      </div>
    </div>
  );
};
