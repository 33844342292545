import "./footer.css";

export default function Footer() {
  return (
    <div className="footer py-5 inter">
      <div className="container">
        <div className="col-12">
          <div>
            <img src="/assets/logo-light.png" alt="logo-light" />
          </div>
        </div>
        <div className="row">
          <div className="col-lg-4 col-xl-4 col-md-4 col-12">
            <div className="my-4 text-white">
              Lorem ipsum dolor sit amet consectetur. Ullamcorper velit amet
              quam quam. Blandit eleifend consequat purus pretium velit orci.
              Posuere adipiscing sit dignissim etiam ultricies.
            </div>
            <div className="socials">
              <div className="social-logo">
                <img src="/assets/twitter.png" alt="twitter" />
              </div>
              <div className="social-logo">
                <img src="/assets/facebook.png" alt="facebook" />
              </div>
              <div className="social-logo">
                <img src="/assets/linkedin.png" alt="linkedin" />
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-xl-4 col-md-4 col-12 mt-4 mt-lg-0 mt-xl-0 mt-md-0">
            <div className="row footer-menus">
              <div className="col-6">
                <div className="footer-heading">Quick Links</div>
                <div className="menus">
                  <div>Home</div>
                  <div>Visa Process</div>
                  <div>Pre-Departure Prep</div>
                  <div>Post-Arrival Essentials</div>
                  <div>Upcoming Webinars</div>
                  <div>Book an Appointment</div>
                </div>
              </div>
              <div className="col-6 text-end">
                <div className="footer-heading">Company</div>
                <div className="menus">
                  <div>Lorem ipsum</div>
                  <div>Lorem ipsum</div>
                  <div>Lorem ipsum</div>
                  <div>Lorem ipsum</div>
                  <div>Lorem ipsum</div>
                  <div>Lorem ipsum</div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-xl-4 col-md-4 col-12">
            <div className="footer-heading">Subscribe Our Newsletter</div>
            <div className="email">
              <input type="text" placeholder="Email" />
              <button className="subscribe-button">Subscribe</button>
            </div>
          </div>
        </div>
        <div className="row mt-5">
          <div className="line"></div>
        </div>
        <div className="row copyrights">
          <div className="col-12 mt-4">
            <div className="d-flex justify-content-between">
              <div>Copyright © 2024 The US Academy. All rights reserved.</div>
              <div className="footer-links-all">
                <a href="/" className="footer-links">
                  Privacy Policy
                </a>
                <a href="/" className="footer-links">
                  Terms & Services
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
