import "./main-content.css";
export default function MainContent() {
  return (
    <div className="row m-content">
      <div className="col-lg-6 col-xl-6 col-12 col-md-6 mt-5 pt-5">
        <div className="h-100 d-flex flex-column justify-content-center">
          <img src="/assets/america-visa.png" alt="america visa" />
          <h1 className="heading">American Dream With The US Academy</h1>
        </div>
      </div>
      <div className="col-lg-3 col-xl-3 col-12 col-md-6 mt-5 pt-5">
        <div className="cta-box">
          Sim Card <br /> to Buy
        </div>
        <div className="cta-box">
          How to open
          <br /> a bank account
        </div>
      </div>
      <div className="col-3 d-none d-lg-block d-xl-block">
        {/* <div className="d-flex justify-content-center">
          <img src="assets/vector.png" alt="vector art" />
        </div> */}
      </div>
      <div className="col-12">
        <button className="cta-btn sign-up">Get Started Now</button>
      </div>
    </div>
  );
}
