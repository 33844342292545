import "./course-curriculum.css";

export default function CourseCurriculum() {
  return (
    <div className="curriculum-background">
      <h1 className="curriculum-heading py-5 text-center">Course Curriculum</h1>
      <div>
        <div className="col-6 offset-3 text-center accordion-section">
          <div className="accordion" id="course-accordion">
            <div className="accordion-item">
              <h2 className="accordion-header" id="headingOne">
                <button
                  className="accordion-button accordion-heading"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseOne"
                  aria-expanded="true"
                  aria-controls="collapseOne"
                >
                  <span>5 Module</span>
                  <span>
                    <div className="curriculum-divider"></div>
                  </span>
                  <span>2.5 hours of video content</span>
                </button>
              </h2>
              <div
                id="collapseOne"
                className="accordion-collapse collapse show"
                aria-labelledby="headingOne"
                data-bs-parent="#course-accordion"
              >
                <div className="accordion-body">
                  <strong>This is the first item's accordion body.</strong> It
                  is shown by default, until the collapse plugin adds the
                  appropriate classNamees that we use to style each element.
                  These classNamees control the overall appearance, as well as
                  the showing and hiding via CSS transitions. You can modify any
                  of this with custom CSS or overriding our default variables.
                  It's also worth noting that just about any HTML can go within
                  the <code>.accordion-body</code>, though the transition does
                  limit overflow.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
