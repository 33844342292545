import { useState } from "react";
import "./header.css";
export default function Header() {
  const [mobileMenu, showMobileMenu] = useState(false);
  return (
    <>
      <div className="row mt-4 d-none d-lg-flex d-xl-flex">
        <div className="col-3">
          <img src="/assets/logo.png" alt="logo" />
        </div>
        <div className="col-6">
          <div className="main-menu">
            <a href="/">Visa Process</a>
            <a href="/">Pre Departure Prep</a>
            <a href="/">Upcoming Webinars</a>
          </div>
        </div>
        <div className="col-3">
          <div className="cta">
            <a
              className="cta-btn sign-in"
              href={`${process.env.REACT_APP_LMS_URI}/dashboard/`}
            >
              Sign In
            </a>
            <a
              className="cta-btn sign-up"
              href={`${process.env.REACT_APP_LMS_URI}/student-registration/?redirect_to=${process.env.REACT_APP_LMS_URI}`}
            >
              Sign Up
            </a>
          </div>
        </div>
      </div>
      <div className="row mt-4 d-flex d-lg-none d-xl-none">
        <div className="col-6">
          <img src="/assets/logo.png" alt="logo" />
        </div>
        <div
          className="col-6 text-end"
          onClick={() => showMobileMenu(!mobileMenu)}
        >
          <img src="/assets/hamburger-menu.png" width="50" alt="logo" />
        </div>
      </div>
      <div className={`row ${mobileMenu ? "d-block" : "d-none"}`}>
        <div className="col-12 mobile-menu">
          <ul>
            <li>
              <a href="/">Visa Process</a>
            </li>
            <li>
              <a href="/">Pre Departure Prep</a>
            </li>
            <li>
              <a href="/">Upcoming Webinars</a>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
}
